import axios from "axios";
export const URL = "https://insta-integration.appssquare.com/api/admin";
const instance = axios.create({
  baseURL: `${URL}`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("lifeToken")}`,
    "X-Role": "client",
  },
});
instance.interceptors.request.use((req) => {
  return req;
});
instance.interceptors.response.use(
  (res) => {
    window.localStorage.setItem("countA", res.headers["joining-requests"]);
    window.localStorage.setItem("countB", res.headers.modification);
    return res;
  },
  (err) => {
    if (
      err &&
      err.response &&
      err.response.status === 403 &&
      err.response.config.url !== "admin/login"
    ) {
      // window.localStorage.removeItem("medtoken");
      // window.location.replace("/");
    } else if (
      err.response.status === 401 &&
      err.response.config.url !== "admin/login"
    ) {
      window.localStorage.removeItem("lifeToken");
      window.location.replace("/");
    }
    // Important: response interceptors **must** return the response.
    // return err;
    else return Promise.reject(err);
  }
);
export default instance;
